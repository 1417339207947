import styles from './ImageSlider.module.scss';
import { Splide, SplideSlide } from '@splidejs/react-splide';

type Props = {
  urls: string[];
  isFullScreen: boolean;
};

export default function ImageSlider({ urls, isFullScreen }: Props) {
  return urls.length > 0 ? (
    <Splide options={{ arrows: false, pagination: true }} className={`${styles.root} ${isFullScreen && styles.full}`}>
      {urls.map((url, i) => (
        <SplideSlide key={i}>
          <img src={url} alt="" />
        </SplideSlide>
      ))}
    </Splide>
  ) : null;
}
