import urlParser from 'js-video-url-parser';
import { useEffect } from 'react';
import styles from './VideoPlayer.module.scss';

type Props = { url: string };

export default function VideoPlayer({ url }: Props) {
  const { id }: any = urlParser.parse(url);

  useEffect(() => {
    // @ts-ignore
    new YT.Player('player', {
      videoId: id,
      playerVars: { rel: 0, playsinline: 1, modestbranding: 1 },
    });
  }, [id]);

  return (
    <div className={styles.root}>
      <div id="player" className={styles.video}></div>
    </div>
  );
}
