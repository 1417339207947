import ImageSlider from 'components/ImageSlider';
import InnerHTML from 'components/InnerHTML';
import Spinner from 'components/Spinner';
import VideoPlayer from 'components/VideoPlayer';
import { useFetchQuery } from 'hooks/useQuery';
import { useLocation, useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';
import styles from './Article.module.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type Article = {
  category: string;
  article_id: string;
  article_title: string;
  article_section: string;
  layout_detail: {
    layout_id: string;
    layout_name: string;
    content: string;
    cta_text: string;
    cta_url: string;
    image_urls: string[];
    video_urls: string[];
    is_radiobutton: boolean;
    is_checkbox: boolean;
    poll_list: [{ poll_id: string; poll_text: string; vote_count: 0; poll_order: 0 }];
    poll_question: string;
    poll_desc: string;
    poll_status: string;
  };
  posted_date: string;
  SendNoti: boolean;
};

export default function Article() {
  const query = useQuery();
  const { id } = useParams();
  const scheme = query.get('scheme')?.toLowerCase() || 'tcm';
  const { data, isLoading } = useFetchQuery<Article>('/articles/GetArticleById', { payload: { id } });
  const article = data?.data;
  const button = article?.layout_detail.cta_text;
  const videos = article?.layout_detail.video_urls;
  const images = article?.layout_detail.image_urls;
  const isFullScreen =
    article?.layout_detail.layout_name === 'Livestream' ||
    article?.layout_detail.layout_name.toLowerCase().includes('full');

  return (
    <main style={{ paddingBottom: !!button ? '12rem' : '' }} className={`${styles.main} ${styles[scheme]} {}`}>
      {!article || isLoading ? (
        <section className={styles.loading}>
          <Spinner size="3rem" />
        </section>
      ) : (
        <>
          {!isFullScreen && (
            <section className={styles.header}>
              <h1>{article.category}</h1>
              <h2>{article.article_title}</h2>
              <h3>{formatDate(article.posted_date, 'dd MMMM yyyy')}</h3>
            </section>
          )}
          <section className={styles.content}>
            {!!videos && videos.length > 0 && <VideoPlayer url={videos[0]} />}
            {!!images && <ImageSlider urls={images} isFullScreen={!!isFullScreen} />}
            <InnerHTML className={styles.text}>{article.layout_detail.content}</InnerHTML>
          </section>
          {!!button && (
            <section className={styles.action}>
              <a href={article.layout_detail.cta_url}>{button}</a>
            </section>
          )}
        </>
      )}
    </main>
  );
}
