import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Article from './pages/article';
import Index from './pages/index';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="article/:id" element={<Article />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
