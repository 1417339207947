import { Request } from 'types/Api';
import api from 'utils/api';

export function useClient() {
  return <T = any>(url: string, config?: Request) => {
    return api<T>(url, { method: 'GET', ...config })
      .then(res => Promise.resolve(res))
      .catch(err => Promise.reject(err));
  };
}
